.flex-main {
  @include transition(0.3s);
  display: flex;
  @media only screen and (min-width: 992px) {
    width: calc(100vw - 290px);
  }
}

.marginLeft {
  margin-left: 290px;
}

.page-full {
  @include transition(0.3s);
  flex-basis: 100%;
  width: 100%;
  @media only screen and (min-width: 992px) {
    transform: translateX(290px);
  }
}
.page-mid {
  @include transition(0.3s);
  flex-basis: 60%;
  @media only screen and (max-width: 1024px) {
    transform: translateX(-100%);
    min-width: 100vw;
  }
}
.editor-open {
  @include transition(0.3s);
  flex-basis: 40%;
  @media only screen and (max-width: 1024px) {
    position: fixed;
    height: 100%;
    flex-basis: 100%;
    transform: translateX(-60%);
  }
}
.editor-close {
  @include transition(0.3s);
  flex-basis: 0%;
  @media only screen and (max-width: 1024px) {
    position: fixed;
    height: 100%;
    transform: translateX(100vw);
  }
}
.editor-button {
  @include transition(0.3s);
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 1000;
  color: #ff4c60;
  font-size: 32px !important;
  cursor: pointer;
  @media only screen and (max-width: 991px) {
    display: none;
  }
}
.editor-button-mobile {
  @include transition(0.3s);
  z-index: 1000;
  color: #ff4c60;
  font-size: 32px !important;
  cursor: pointer;
  float: right;
  margin-top: 3.75px;
  @media only screen and (min-width: 992px) {
    display: none;
  }
}
.menu-hidden {
  @include transition(0.3s);
  transform: translateX(-290px) !important;
}
.move-editor {
  @include transition(0.3s);
  transform: translateX(290px);
  margin: 0px;
}

.move-editor-title {
  @include transition(0.3s);
  transform: translateX(0);
}

.fixed-editor {
  @include transition(0.3s);
  min-width: 300px;
  position: fixed;
  overflow-y: scroll;
  height: 100%;
  padding: 10px;
  padding-bottom: 100px;
  margin: 10px;
  margin-top: 60px;
  @media only screen and (max-width: 1024px) {
    width: 100vw;
  }
  @media only screen and (max-width: 991px) {
    margin-top: 61.5px !important;
  }
}
